import React, { useState, useEffect } from "react";
import "./Landing.css";
import JobSearchFeature from "../components/JobSearchFeature/JobSearchFeature";
import PricingCard from "../components/PricingCard/PricingCard";
import Hotjar from "../components/Hotjar";
import { Linkedin } from "../components/Analytics/Linkedin";
import { SocialProof, Testimonial } from "../components/SocialProof";

const PAYMENT_LINK_ONE_AND_DONE = "https://buy.stripe.com/3csg0Vez44139yw6or";

const SOCIAL_PROOF: Testimonial[] = [
	{
		quote:
			"A thoughtfully customized cover letter serves as a powerful differentiator in the hiring process. By developing targeted content that addresses specific organizational needs and cultural fit, candidates can increase their interview likelihood by up to 50%.",
		source: "HRBrain.ai",
		credentials: "Published - October, 26, 2024"
	},
	{
		quote:
			"The first follow-up email is naturally the most effective. It brings the highest reply rate — even about 40% higher comparing to the initial email.",
		source: "Megan Kawalkowska",
		credentials: "Content Manager @ Woodpecker (April 30, 2024)"
	},
	{
		quote:
			"You need to make sure that your resume addresses the employer’s specific challenges and pain-points–otherwise you’re selling a solution the organization doesn’t need.",
		source: "Ben Forstag",
		credentials: "Managing Director, Mac’s List"
	}
];

const JobSearchHeader: React.FC = () => {
	const [campaign, setCampaign] = useState("");

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const campaign: string | null = searchParams.get("campaign");

		if (!(campaign === null)) {
			setCampaign(campaign);
		}
	}, []);

	return (
		<div className="job-search-landing">
			{/* 
			<div className="job-search-header">			
					<JobSearchFeature imageSrc="mappy-image.png" isLeftHanded={true}>
						<p className="header-content-mappy">
							<span className="text-navy">
								Looking for a job can be a job in itself. Mappy is ready to help.
							</span>
						</p>
					</JobSearchFeature>
			</div> 
			*/}

			<div className="job-search-feature">
				<h2 className="job-search-label">
					<p>JobMap, your job searching assistant. </p>
					<p>This is how it helps you succeed.</p>
				</h2>
				<JobSearchFeature
					title="AI Coverletter Assistant"
					imageSrc="cover-letter-image.png"
					isLeftHanded={false}
				>
					<b>Do you find writing custom coverletters to be time consuming?</b>{" "}
					<p>
						Stop spending hours crafting cover letters. Our AI assistant helps
						you create personalized, professional cover letters in moments,
						letting you focus on what matters.
					</p>
				</JobSearchFeature>

				<JobSearchFeature
					title="Smart Job Application Tracking"
					imageSrc="tracker-image.png"
					isLeftHanded={true}
				>
					<b>Ever forget to follow up or prepare for an interview?</b>
					<p>
						Never lose track of your applications again. Our intuitive tracker
						helps you manage every application, interview, and follow-up in one
						place.
					</p>
				</JobSearchFeature>

				<JobSearchFeature
					title="AI Résumé Processing"
					description="We use cutting-edge customized foundational AI models to process your resume and make an impressive response to specific job postings."
					imageSrc="resume-image.png"
					isLeftHanded={false}
				/>

				<JobSearchFeature
					title="Manual Customization"
					imageSrc="edit-image.png"
					isLeftHanded={true}
				>
					<b>Ever found AI to be less clever than you need?</b>{" "}
					<p>
						Change it, right on the spot. Our platform gives you interactive
						editability of all your content.
					</p>
				</JobSearchFeature>
			</div>

			<div className="job-search-pricing">
				<h2 className="job-search-label">Pricing</h2>
				<PricingCard
					title="One and Done"
					price={24.99}
					description="All of the tools you need to assist your job search."
					features={[
						"AI Coverletter Assistant",
						"Smart Job Application Tracking",
						"AI Résumé Processing",
						"Manual Customization",
						"24x7 Support"
					]}
					vendorId="VID12345"
					paymentLinkUrl={PAYMENT_LINK_ONE_AND_DONE}
					campaignId={campaign ? parseInt(campaign) : undefined}
				/>
			</div>

			<div className="job-search-social-proof">
				<SocialProof socialProof={SOCIAL_PROOF} />
			</div>
		</div>
	);
};

function Landing() {
	return (
		<>
			<Hotjar />
			<Linkedin />
			<JobSearchHeader />
		</>
	);
}

export default Landing;
