import React, { useState, useEffect } from "react";

export interface Testimonial {
	quote: string;
	source: string;
	credentials: string;
}

interface Props {
	socialProof: Testimonial[];
}

export const SocialProof: React.FC<Props> = ({ socialProof }) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentIndex((prevIndex) =>
				prevIndex === socialProof.length - 1 ? 0 : prevIndex + 1
			);
		}, 14000);

		return () => clearInterval(timer);
	}, [socialProof.length]);

	return (
		<div className="w-full max-w-6xl mx-auto px-4">
			<h2 className="text-3xl font-bold text-center mb-12">
				What the Experts Say
			</h2>

			<div className="relative">
				<div className="flex justify-center mb-8">
					<div className="w-full max-w-2xl relative">
						{socialProof.map((testimonial: Testimonial, index: number) => (
							<div
								key={index}
								className={`transition-opacity duration-500 ${
									index === currentIndex
										? "opacity-100"
										: "opacity-0 pointer-events-none absolute top-0 left-0 right-0"
								}`}
							>
								<div className="bg-white rounded-lg shadow-lg p-8 text-center">
									<div className="text-5xl font-serif text-gray-300 mb-4">
										"
									</div>
									<p className="text-gray-700 mb-6 italic">
										{testimonial.quote}
									</p>
									<div className="font-semibold text-gray-900">
										{testimonial.source}
									</div>
									{testimonial.credentials === "" ? (
										""
									) : (
										<div className="text-gray-600 text-sm">
											{testimonial.credentials}
										</div>
									)}
								</div>
							</div>
						))}
					</div>
				</div>

				<div className="flex justify-center space-x-2 mt-4 mb-8">
					{socialProof.map((_: Testimonial, index: number) => (
						<button
							key={index}
							onClick={() => setCurrentIndex(index)}
							className={`h-3 w-3 rounded-full border border-gray-300 transition-all duration-300 ${
								index === currentIndex
									? "bg-gray-800 border-gray-800"
									: "bg-white hover:bg-gray-100"
							}`}
							aria-label={`Go to slide ${index + 1}`}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
